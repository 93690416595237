/**
 * CircularBuffer.
 * A bit like an array but forgets old items
 * Used in situations where you want to ensure the collection does not grow
 * memory usage indefinetely (i.e. a memory leak) and you only want guaranteed
 * access to the most recently added items (determined by the size argument)
 * @returns {void} -
 */
class CircularBuffer {
  /**
   * CircularBuffer constructor.
   * @param {number} size -
   * @returns {void}
   */
  constructor(size) {
    this._size = size;
    this._array = new Array(size);
    this._cursor = 0;
  }

  /**
   * Note this returns a filtered array rather than a filtered buffer to avoid
   * breaking the analytics scripts
   * @param {function} cb -
   * @returns {array} -
   */
  filter(cb) {
    return this.toArray().filter(cb);
  }

  /**
   * Can't use this._array.filter as that would change the length of the array
   * and therefore the positions of the elements
   * @param {function} cb -
   * @returns {void} -
   */
  filterBuffer(cb) {
    // eslint-disable-next-line
    this._array = this._array.map((elem) => {
      if (typeof elem !== 'undefined' && cb(elem)) {
        return elem;
      }
    });
  }

  /**
   * It pushes an item in the array.
   * @param {any} item -
   * @returns {void} -
   */
  push(item) {
    this._array[this._cursor] = item;
    this._incCursor();
  }

  /**
   * we need to ensure the items are in the correct order (oldest to newest)
   * @returns {array} -
   */
  toArray() {
    return this._array
      .slice(this._cursor)
      .concat(this._array.slice(0, this._cursor))
      .filter((item) => !!item); // remove any "holes" if the buffer isn't full
  }

  /**
   * It increments the cursor.
   * @returns {void} -
   */
  _incCursor() {
    this._cursor += 1;

    if (this._cursor >= this._size) {
      this._cursor -= this._size;
    }
  }
}

export default CircularBuffer;
