import emitter from './emitter';

import type { Unsubscribe } from './typings';

/**
 * Subscribe to an event on the Event Bus
 * @module event-bus/on
 * @param {string} type Event name
 * @param {Function} listener Callback for when events are dispatched
 * @returns {Function} Unsubscribe function
 */
export const on = <T extends { name: string; data: any } = { name: string; data: any }>(
  type: T['name'],
  listener: (data: T['data']) => void
): Unsubscribe => {
  emitter.on<T['data']>(type, listener);

  return () => {
    emitter.off(type, listener);
  };
};
