import { isFunction } from '../shared/utils';
import config from './config';

/**
 * reportError function
 *
 * Call consumer's error handler function if registered using `registerApmErrorHandler`, throw error otherwise.
 *
 * @param {String} errorMessage - The error message string
 * @returns {void}
 */
export const reportError = (errorMessage: string) => {
  const { reportError: reportErrorByConsumer } = config.get();

  if (isFunction(reportErrorByConsumer)) {
    return reportErrorByConsumer(errorMessage);
  }

  throw new Error(errorMessage);
};

/**
 * registerApmErrorHandler function
 *
 * This function initialises the error handler config with the data provided by the consumer,
 *
 * @param {String} errorHandlerConfig - Error handler config provided by consumer
 * @returns {void}
 */
export default (errorHandlerConfig: Record<string, any>) => {
  config.set(errorHandlerConfig);
};
