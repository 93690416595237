/* global newrelic */
import { getCustomErrorAttributes } from '../shared/get-custom-error-attributes';
/**
 * logApmError function
 *
 * This function logs single key and value.
 * @param {Object} error - The error object for logging.
 * @param {any} attributes - The customErrorMessage for logging.
 * @returns {void}
 */

export default function logApmError(error?: Error, attributes?: Record<string, any>) {
  // @ts-expect-error window property
  if (typeof window.newrelic !== 'undefined') {
    if (!(error instanceof Error)) {
      error = new Error('Error object not provided'); // eslint-disable-line no-param-reassign
    }

    // @ts-expect-error window property
    newrelic.noticeError(error, getCustomErrorAttributes(error, attributes));
  }
}
