import minimatch from 'minimatch';

const translationFormat = /^([^_]+)_([^_]+)$/;

type Messages = Record<string, string>;
type Locale = string;

const keyDiff = (a: string[], b: string[]) => {
  const aSet = new Set(a);
  const bSet = new Set(b);

  const added: string[] = [];
  const removed: string[] = [];

  aSet.forEach((key) => {
    if (!bSet.has(key)) {
      removed.push(key);
    }
  });

  bSet.forEach((key) => {
    if (!aSet.has(key)) {
      added.push(key);
    }
  });

  return { added, removed };
};

const baseValidateTranslations = (
  errors: string[],
  messages: Messages,
  keys: string[],
  { locale }: { locale?: string } = {}
) => {
  const { added, removed } = keyDiff(keys, Object.keys(messages));
  const specifier = locale != null ? ` for ${locale}` : '';

  if (removed.length !== 0) {
    errors.push(`Missing translations${specifier}: ${removed.join(', ')}`);
  }

  if (added.length !== 0) {
    errors.push(`Extraneous translations${specifier}: ${added.join(', ')}`);
  }
};

export const validateTranslations = (messages: Messages, keys: string[], options?: { locale?: string }): Messages => {
  const errors: string[] = [];
  baseValidateTranslations(errors, messages, keys, options);

  if (errors.length !== 0) {
    throw new Error(errors.join('\n'));
  }

  return messages;
};

export const ungroupMessages = (
  language: string,
  regions: string[],
  messages: Messages,
  { keys }: { keys?: string[] } = {}
): Record<Locale, Messages> => {
  const localeFor = (region: string) => `${language}-${region}`;

  const baseValues: Messages = {};
  const overriddenValuesByLocale: Record<Locale, Messages> = {};

  regions.forEach((region) => {
    overriddenValuesByLocale[localeFor(region)] = {};
  });

  Object.entries(messages).forEach((tuple) => {
    const value = tuple[1];

    const match = tuple[0].match(translationFormat);

    if (match == null) {
      const key = tuple[0];
      baseValues[key] = value;
      return;
    }

    const { 1: key, 2: regionMinimatch } = match;

    regions
      .filter((region) => minimatch(region, regionMinimatch))
      .forEach((region) => {
        const overriddenValues = overriddenValuesByLocale[localeFor(region)];

        if (overriddenValues[key] == null) {
          overriddenValues[key] = value;
        } else {
          throw new Error(`Translation "${key}" was applied twice to region "${region}"`);
        }
      });
  });

  const out: Record<Locale, Messages> = {};
  const errors: string[] = [];

  regions.forEach((region) => {
    const locale = localeFor(region);

    const translations = {
      ...baseValues,
      ...overriddenValuesByLocale[locale]
    };
    out[locale] = translations;

    if (keys != null) {
      baseValidateTranslations(errors, translations, keys, { locale });
    }
  });

  if (errors.length !== 0) {
    throw new Error(errors.join('\n'));
  }

  return out;
};
