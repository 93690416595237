import { isString, hasValidDataType, isPrimitive } from '../../shared/utils';
import { reportError } from '../../error-handler';

/**
 * logApmDatum function
 *
 * This function logs single key and value.
 * @param {String} key - The key needs for logging.
 * @param {String | Number} value - The value needs for logging.
 * @returns {void}
 */

// newrelic setCustomAttribute supports only string and number for value
const allowedDataTypes = ['string', 'number'];

export default function logApmDatum(key: string, value: any) {
  // @ts-expect-error window property
  const { newrelic } = window;

  if (!newrelic) {
    return reportError('newrelic script is not yet loaded');
  }

  if (!isString(key)) {
    return reportError(
      `Incorrect arguments for logApmDatum! First argument should be of type string but found ${typeof key}`
    );
  }

  let customValue = value;

  if (!hasValidDataType(customValue, allowedDataTypes)) {
    // !NOTE: Please remove this if we want to be more aligned towards how newrelic supports the second parameter
    if (isPrimitive(customValue)) {
      customValue = `${customValue}`;
    } else {
      return reportError(
        `Incorrect arguments for logApmDatum! Second argument should be of type string/number but found ${typeof customValue}`
      );
    }
  }

  newrelic.setCustomAttribute(key, customValue);
}
