/* eslint-disable func-names */
import logWindowError from './log-window-error';

/**
 * loadGenericErrorHandlingScript function
 *
 * This function add handler logWindowError to onerror event.
 * @returns {void}
 */
export default function loadGenericErrorHandlingScript() {
  // @ts-expect-error window property
  if (typeof window !== 'undefined' && window.NREUM) {
    // @ts-expect-error window property
    window.onerror = logWindowError;
  }
}
