import { ExperimentAttributes } from '../../../typings';
import { EXPERIMENTS_ATTRIBUTE_NAME } from '../shared/experiment-constants';
import logApmDatum from './log-apm-datum';

type Context = {
  [EXPERIMENTS_ATTRIBUTE_NAME]: string | undefined;
};

/**
 * logExperiment
 *
 * This function records a custom event for experiments and sends it to new relic.
 *
 * @param {Object}        experimentDetails - An object, that contains key: value pairs of custom attributes we want to log
 * @param {string}        experimentDetails.experimentName - The name of the experiment
 * @param {string | null} experimentDetails.variant - The variant for the experiment
 * @returns {void}
 */
export default function logExperiment(experimentAttributes: ExperimentAttributes) {
  const { experimentName, variant } = experimentAttributes;

  if (variant == null) {
    return;
  }

  // @ts-expect-error window property
  const { newrelic } = window;

  if (!newrelic) {
    return;
  }

  // New relic documentation  for interaction() - https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/interaction/
  const transaction = newrelic.interaction();

  if (!transaction) {
    return;
  }

  // New relic documentation for getContext - https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/getcontext/
  // Don't trust newrelic so handle if method does not exists
  // eslint-disable-next-line no-unused-expressions
  transaction.getContext?.((context: Context) => {
    // check if experiments attribute has already been set during transaction
    if (!context[EXPERIMENTS_ATTRIBUTE_NAME]) {
      context[EXPERIMENTS_ATTRIBUTE_NAME] = `${experimentName}:${variant}`;

      logApmDatum(EXPERIMENTS_ATTRIBUTE_NAME, context[EXPERIMENTS_ATTRIBUTE_NAME]);
    } else if (!context[EXPERIMENTS_ATTRIBUTE_NAME].includes(experimentName)) {
      context[EXPERIMENTS_ATTRIBUTE_NAME] = context[EXPERIMENTS_ATTRIBUTE_NAME].concat(`,${experimentName}:${variant}`);

      logApmDatum(EXPERIMENTS_ATTRIBUTE_NAME, context[EXPERIMENTS_ATTRIBUTE_NAME]);
    }
  });
}
