import { ApiRequestAttributes } from '../../../typings';

import { API_REQUEST_EVENT_NAME } from '../../shared/constants';

function logApiRequestEvent(apiRequestDetails: ApiRequestAttributes) {
  const {
    atrc,
    region,
    requestHost,
    requestMethod,
    requestName,
    requestPath,
    responseStatus,
    responseTimeMs,
    apiRequestTraceId,
    traceId,
    customAttributes = {}
  } = apiRequestDetails;

  const logCustomEvent = process.env.CLIENT_SIDE
    ? require('../client').logCustomEvent
    : require('../server').logCustomEvent;

  logCustomEvent(API_REQUEST_EVENT_NAME, {
    ...customAttributes,
    atrc,
    region,
    requestHost,
    requestMethod: requestMethod || 'GET',
    requestName,
    requestPath,
    responseStatus: responseStatus || 999, // 999 signifies a lack of status in this case, but enables us to distinguish it from other, genuine statuses
    responseTimeMs,
    apiRequestTraceId,
    trace_id: traceId
  });
}

export default logApiRequestEvent;
