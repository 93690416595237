/**
 * isString function
 *
 * Helper function to check whether input is of type string.
 *
 * @param {String} input - The input value
 * @returns {Boolean} - Returns true if input type is string, false otherwise
 */
const isString = (input: any) => typeof input === 'string';

/**
 * isFunction function
 *
 * Helper function to check whether input is of type function.
 *
 * @param {String} input - The input value
 * @returns {Boolean} - Returns true if input type is function, false otherwise
 */
const isFunction = (input: any) => typeof input === 'function';

/**
 * isObject function
 *
 * Helper function to check whether input is of type object(not array).
 *
 * @param {String} input - The input value
 * @returns {Boolean} - Returns true if input type is object(not array), false otherwise
 */
const isObject = (input: any) => !!(input && typeof input === 'object' && !Array.isArray(input));

/**
 * isEmpty function
 *
 * Helper function to check whether input is an empty object.
 *
 * @param {String} input - The input value
 * @returns {Boolean} - Returns true if input type is an empty object, false otherwise
 */
const isEmpty = (input: any) => isObject(input) && Object.entries(input).length === 0;

/**
 * isDataTypeValid function
 * @param {any} input Provide any input to be checked for datatype
 * @param {Array} types Array of string (types) against which, it need to be validated
 * @returns {Boolean} - Returns true if matches, false otherwise
 */
const hasValidDataType = (input: any, types: Array<string>) => {
  if (types.includes(typeof input)) {
    return true;
  }

  return false;
};

/**
 * isDataTypeValid function
 * @param {any} input Provide any input to be checked for primitive
 * @returns {Boolean} - Returns true if primitive, false otherwise
 */
const isPrimitive = (input: any) => {
  const type = typeof input;
  // symbol is primitive but added intentionally here
  return input === null || (type !== 'object' && type !== 'function' && type !== 'symbol');
};

/**
 * This function helps get the object flattened to the top level
 * @param {Object} currentNode The object to be flattened
 * @param {Object} target The target object to get the flattened data
 * @param {String} flattenedKey The option key to be used for flatten prop
 */
const traverseAndFlatten = (currentNode: Record<string, any>, target: Record<string, any>, flattenedKey?: string) => {
  if (!currentNode || !isObject(target)) {
    return target;
  }

  for (const key in currentNode) {
    if (Object.prototype.hasOwnProperty.call(currentNode, key)) {
      let newKey: string;

      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = `${flattenedKey}.${key}`;
      }

      const value = currentNode[key];

      if (typeof value === 'object') {
        traverseAndFlatten(value, target, newKey);
      } else if (hasValidDataType(value, ['string', 'number', 'boolean'])) {
        target[newKey] = value;
      } else {
        target[newKey] = `${value}`;
      }
    }
  }

  return target;
};

export { isString, isFunction, isObject, isEmpty, traverseAndFlatten, hasValidDataType, isPrimitive };
