/* eslint-disable global-require */
import { ExperimentAttributes, ApiRequestAttributes } from '../../typings';
import { reportError } from '../error-handler';
import { ApmTool } from '../shared/constants';

export { default as registerApmErrorHandler } from '../error-handler';
export { ApmTool };

let newrelicClient: any;

/**
 * initApm function
 *
 * This function initialises the specific apmTool's profile with the config provided by the application,
 * when the application that consumes the pea is running and being monitored (as opposed to just executing a script).
 *
 * @param {String} apmTool - The apmTool to be used for app monitoring
 * @returns {void}
 */
export function initApm(apmTool: ApmTool) {
  if (apmTool !== ApmTool.NEWRELIC) {
    return reportError('Unsupported apmTool provided!');
  }

  // Note - lazy load here as newrelic is an optional peer dependency
  newrelicClient = require('../newrelic/client');
}

/**
 * logCustomEvent
 *
 * This function records a key value custom event and sends it to the apm tool.
 *
 * @param {String} eventType - This is the name of the schema table that we want to post our data to
 * @param {Object | Array} attributes - An object, or array of objects, that contains key: value pairs of custom attributes we want to log
 * @returns {void}
 */
export function logCustomEvent(eventType: string, attributes: Record<string, any> | Array<Record<string, any>>) {
  if (newrelicClient) {
    newrelicClient.logCustomEvent(eventType, attributes);
  }
}

/**
 * logApmData function
 *
 * This function is used to log multiple data key/value pairs wrapped in the form of an object to the apm tool.
 *
 * @param {Object} customAttributes - The set of custom attributes to be logged in the apm tool
 * @returns {void}
 */
export const logApmData = (customAttributes: Record<string, any>) => {
  if (newrelicClient) {
    newrelicClient.logApmData(customAttributes);
  }
};

/**
 * logApmDatum function
 *
 * This function is used to log data in the form of key/value pair to the apm tool.
 *
 * @param {String} key - The key to log a custom attribute in the apm tool
 * @param {String} value - The corresponding value of the custom attribute to be logged in the apm tool
 * @returns {void}
 */
export const logApmDatum = (key: string, value: any) => {
  if (newrelicClient) {
    newrelicClient.logApmDatum(key, value);
  }
};

/**
 * logApmError function
 *
 * This function logs an exception.
 * @param {Object} error - The error object for logging.
 * @param {String} message - The message for logging.
 * @param {Array} tags - The tags for logging.
 * @returns {void}
 */
export const logApmError = (error: Error, message: string, tags: Array<string>) => {
  if (newrelicClient) {
    const attributes = { message, tags };
    newrelicClient.logApmError(error, attributes);
  }
};

/**
 * logExperiment
 *
 * This function records a custom event for experiments and sends it to new relic.
 *
 * @param {Object}        experimentDetails - An object, that contains key: value pairs of custom attributes we want to log
 * @param {string}        experimentDetails.experimentName - The name of the experiment
 * @param {string | null} experimentDetails.variant - The varaint for the experiment
 * @returns {void}
 */
export const logExperiment = (experimentDetails: ExperimentAttributes) => {
  if (newrelicClient) {
    newrelicClient.logExperiment(experimentDetails);
  }
};

/**
 * logApiRequestEvent
 *
 * This function records a custom event for api request data and sends it to new relic.
 *
 * @param {Object}        apiRequestDetails - An object, that contains key: value pairs of custom attributes we want to log for api requests
 * @param {string}        apiRequestDetails.region - Country for which the request was made
 * @param {string | null} apiRequestDetails.atrc - Users session id
 * @param {string}        apiRequestDetails.requestHost - Request host of api
 * @param {string}        apiRequestDetails.requestMethod - The method type of request
 * @param {string}        apiRequestDetails.requestName - The name of the api request
 * @param {string}        apiRequestDetails.requestPath - The path or endpoint of the api request
 * @param {string}        apiRequestDetails.apiRequestTraceId - Request header trace id to downstream api or Mango
 * @param {number}        apiRequestDetails.responseStatus - The response status of request
 * @param {number}        apiRequestDetails.responseTimeMs - Response time of request in Ms
 * @param {string | null} apiRequestDetails.traceId - Associated trace id
 * @param {Object}        apiRequestDetails.customAttributes - Object of any other custom attributes to be recorded
 * @returns {void}
 */
export const logApiRequestEvent = (apiRequestDetails: ApiRequestAttributes) => {
  if (newrelicClient) {
    newrelicClient.logApiRequestEvent(apiRequestDetails);
  }
};

/**
 *  * setTransactionName function
 *
 * @param {String} name - The name for the transaction.
 * @param {String | undefined} domain - Domain for the transaction.
 * @returns {void}
 */
export const setTransactionName = (name: string, domain: string | undefined) => {
  if (newrelicClient) {
    newrelicClient.setTransactionName(name, domain);
  }
};
