/**
 * @module optimizely-react
 */

import { emit, on } from '@peas/event-bus';
import { createContext, useContext, useMemo, useRef } from 'react';

export type Experiment = {
  flagKey: string;
  variables?: { variationKey: string; experimentKey: string } & { [key: string]: string | number | boolean };
  variationKey: string;
  enabled?: boolean;
  userId?: string;
};

export type ExperimentAttributes = Record<string, string | number | boolean>;
// this is the type that the Orchestrator serializes and returns to us
export type Experiments = {
  attributes?: ExperimentAttributes;
  session?: {
    attributes: ExperimentAttributes;
  } & Record<string, Experiment>;
} & Record<string, Experiment>;

type OptimizelyData = {
  attributes?: ExperimentAttributes;
  sessionAttributes?: ExperimentAttributes;
} & Record<string, Experiment>;

type With = typeof window & {
  optimizelyData: OptimizelyData;
  __unsubSpaTransition: () => void;
};

const defaultExperimentsContext: Experiments = {};
const ExperimentsContext = createContext(defaultExperimentsContext);
export const ExperimentsProvider = ExperimentsContext.Provider;

if (typeof window !== 'undefined') {
  if (!(window as With).optimizelyData) {
    // Only do this once, regardless of how many times this hook
    // was used by different React applications
    (window as With).__unsubSpaTransition = on('spaTransition', () => {
      (window as With).optimizelyData = {} as any;
    });

    (window as With).optimizelyData = {} as any;
  }
}

/*
This wraps a context around window.optimizeyData to cache when we
are emitting analytics events and to provide feedback for devs

We handle resetting above in the window logic so that we don't emit multiple times
should this package be included on different versions by MFEs
*/
function useEmitExperimentMemo(
  experiments: Experiments
): (experimentName: string, experiment: Experiment, emitAnalytics: boolean) => void {
  const emittedExperimentsRef = useRef<OptimizelyData>({});

  // Cache any emitted experiments in the window.optimizelyData object
  if (typeof window !== 'undefined' && (window as With).optimizelyData) {
    emittedExperimentsRef.current = (window as With).optimizelyData;

    if (!emittedExperimentsRef.current.attributes) {
      emittedExperimentsRef.current.attributes = experiments.attributes ?? {};
    }

    if (!emittedExperimentsRef.current.sessionAttributes) {
      emittedExperimentsRef.current.sessionAttributes = experiments.session?.attributes ?? {};
    }
  }

  const useEmit = useMemo(
    () => (experimentName: string, experiment: Experiment, emitAnalytics: boolean) => {
      if (!emittedExperimentsRef.current[experimentName]) {
        let variationKey = experiment.variables?.variationKey ?? experiment.variationKey;
        const experimentKey = experiment.variables?.experimentKey ?? experimentName;

        const parts = variationKey.split('-');

        // When the variation key is in the form 123-a, we should just use `a` for analytics
        if (parts.length >= 2) {
          // eslint-disable-next-line prefer-destructuring
          variationKey = parts.slice(1).join('-');
        }

        // memo that we've already emitted, and expose experiment data
        // make sure we use experimentName rather than variation key here
        emittedExperimentsRef.current[experimentName] = experiment;

        if (emitAnalytics) {
          emit('experimentActivated', {
            experiments: [{ name: experimentKey, variant: variationKey }],
            ref: 'AllPages'
          });
        }
      }
    },
    [emittedExperimentsRef]
  );

  return useEmit;
}

/**
 * A hook to access experiment details by experiment name
 * @param  {string} experimentName - the experiment name
 * @returns {Experiment} - object
 */
const useExperiment = (
  experimentName: string,
  dataSelector: (exp: Experiments) => Experiments
): Experiment | Record<string, never> => {
  const allExperiments = useContext(ExperimentsContext);
  const selected = dataSelector(allExperiments);
  const experiment = selected[experimentName.toLowerCase()];

  const memoEmitExperimentOp = useEmitExperimentMemo(allExperiments);

  if (experiment) {
    memoEmitExperimentOp(experimentName, experiment, true);

    return experiment;
  }

  return {};
};

const useExperimentConditionally = (
  featureKey: string,
  shouldUse: boolean,
  dataSelector: (exp: Experiments) => Experiments
): Experiment | Record<string, never> => {
  const allExperiments = useContext(ExperimentsContext);
  const selected = dataSelector(allExperiments);
  const experiment = selected[featureKey.toLowerCase()];
  const memoEmitExperimentOp = useEmitExperimentMemo(allExperiments);

  if (!shouldUse || !experiment) {
    return {};
  }

  if (experiment && shouldUse) {
    memoEmitExperimentOp(featureKey, experiment, true);

    return experiment;
  }

  return {};
};

const customerExperimentPick = (e: Experiments) => (e.session ? e : {});
const sessionExperimentPick = (e: Experiments) => e.session ?? e;

export function useExperimentForCustomer(featureKey: string): Experiment | Record<string, never> {
  return useExperiment(featureKey, customerExperimentPick);
}

export function useExperimentForCustomerConditionally(
  featureKey: string,
  shouldUse: boolean
): Experiment | Record<string, never> {
  return useExperimentConditionally(featureKey, shouldUse, customerExperimentPick);
}

export function useExperimentForSession(featureKey: string): Experiment | Record<string, never> {
  return useExperiment(featureKey, sessionExperimentPick);
}

export function useExperimentForSessionConditionally(
  featureKey: string,
  shouldUse: boolean
): Experiment | Record<string, never> {
  return useExperimentConditionally(featureKey, shouldUse, sessionExperimentPick);
}

export function useFeatureFlag(featureKey: string): Experiment | Record<string, never> {
  const allExperiments = useContext(ExperimentsContext);
  const memoEmitExperimentOp = useEmitExperimentMemo(allExperiments);

  const lowerCaseName = featureKey.toLowerCase();
  // we do not fall back to experiments.session because:
  //  - if the user is not logged in then allExperiments = session
  //  - if the user is logged in then allExperiments = customer
  // either way we get the feature flag value
  const experiment: Experiment | undefined = allExperiments?.[lowerCaseName];

  if (experiment) {
    memoEmitExperimentOp(featureKey, experiment, false);
    return experiment;
  }

  return {};
}
