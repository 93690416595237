import emitter from './emitter';

/**
 * Publish an event to the Event Bus
 * @module event-bus/emit
 * @param {string} type Event name
 * @param {Object} payload Data for event
 * @returns {void} Nothing
 */
export const emit = <T extends { name: string; data: any } = { name: string; data: any }>(
  type: T['name'],
  payload: T['data']
): void => {
  emitter.emit(type, payload);
};
