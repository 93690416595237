import logApmError from './log-apm-error';

/**
 * logWindowError function
 *
 * This function logs any window error.
 * @param {String} msg - The msg needs for logging.
 * @param {String} url - The url needs for logging.
 * @param {String} lineNo - The lineNo for logging.
 * @param {String} columnNo - The columnNo for logging.
 * @param {String} error - The error for logging.
 * @returns {void}
 */
export default function logWindowError(msg: string, url: string, lineNo: number, columnNo: number, error: string) {
  let errMessage;

  if (msg.toLowerCase().includes('script error')) {
    /*
      If an error is thrown from a different origin the browser will mask its details and React will 
      not be able to log the original error message. This is a security precaution taken by browsers 
      to avoid leaking sensitive information. In develop mode this piece of code will run and log error
      to developer console.
    */
    errMessage = 'Script Error: See browser console for detail(s)!';
  } else {
    errMessage = `Message: ${msg} URL: ${url} lineNo: ${lineNo} columnNo: ${columnNo} error: ${error}`;
  }

  logApmError(new Error(errMessage));
}
