import { traverseAndFlatten } from '../../shared/utils';

/**
 * This function is used to get flattened attributes
 * @param {Object} attributes
 * @returns {Object} flattened attributes
 */
export function getFlattenedAttributes(attributes: Record<string, any>) {
  const flattenedAttributes = {};

  traverseAndFlatten(attributes, flattenedAttributes);

  return flattenedAttributes;
}
