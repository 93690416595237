type NRConfig = {
  licenseKey: string;
  trustKey: string;
  applicationID: string;
  accountID: string;
  agentID: string;
  distributedTracing: string;
  cookieCollection: string;
  featureFlags: string;
} | null;

let newrelicConfig: NRConfig;

export const nreumConfig = {
  get: () => newrelicConfig || {},
  set: (nrConfig: NRConfig) => {
    newrelicConfig = nrConfig;
  }
};

export function getFeatureFlags(featureFlags: string | undefined): string[] {
  return featureFlags ? featureFlags.split(',') : [];
}

/**
 * configApm function
 *
 * Returns all necessary client scripts for NewRelic APM.
 * @param {boolean} generateBrowserConfigScripts - If true it will all necessary client scripts for NewRelic APM
 */
export default function configApm(generateBrowserConfigScripts = false) {
  if (!generateBrowserConfigScripts) {
    nreumConfig.set(null);
    return;
  }

  const {
    NEW_RELIC_BROWSER_ACCOUNT_ID: accountID,
    NEW_RELIC_BROWSER_AGENT_ID: agentID,
    NEW_RELIC_BROWSER_LICENSE_KEY: licenseKey,
    NEW_RELIC_BROWSER_TRUST_KEY: trustKey,
    NEW_RELIC_BROWSER_APPLICATION_ID: applicationID,
    NEW_RELIC_BROWSER_DISTRIBUTED_TRACING: tracing,
    NEW_RELIC_BROWSER_COOKIE_COLLECTION: collection,
    NEW_RELIC_BROWSER_FEATURE_FLAGS: featureFlags
  } = process.env;

  const distributedTracing = tracing === 'true';
  const cookieCollection = collection === 'true';

  const errMsg = 'New Relic browser configuration variable is missing:';

  if (!accountID) {
    throw new Error(`${errMsg} NEW_RELIC_BROWSER_ACCOUNT_ID`);
  }

  if (!agentID) {
    throw new Error(`${errMsg} NEW_RELIC_BROWSER_AGENT_ID`);
  }

  if (!licenseKey) {
    throw new Error(`${errMsg} NEW_RELIC_BROWSER_LICENSE_KEY`);
  }

  if (!trustKey) {
    throw new Error(`${errMsg} NEW_RELIC_BROWSER_TRUST_KEY`);
  }

  if (!applicationID) {
    throw new Error(`${errMsg} NEW_RELIC_BROWSER_APPLICATION_ID`);
  }

  nreumConfig.set({
    licenseKey: JSON.stringify(licenseKey),
    trustKey: JSON.stringify(trustKey),
    applicationID: JSON.stringify(applicationID),
    accountID: JSON.stringify(accountID),
    agentID: JSON.stringify(agentID),
    distributedTracing: JSON.stringify(Boolean(distributedTracing)),
    cookieCollection: JSON.stringify(Boolean(cookieCollection)),
    featureFlags: JSON.stringify(getFeatureFlags(featureFlags))
  });
}
