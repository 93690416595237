let errorHandlerConfig: Record<string, any>;

/**
 * This function is used internally by `registerApmErrorHandler`.
 * This will hold error handler configuration passed by application/consumer.
 */

export default {
  get: () => errorHandlerConfig || {},
  set: (config: Record<string, any>) => {
    errorHandlerConfig = config;
  }
};
