import { reportError } from '../../error-handler';

/**
 * setTransactionName function
 *
 * This function sets the transaction name for browserInteraction and pageView events
 * @param {String} name - The name of the browser transaction.
 * @param {String | undefined} domain - Domain for the transaction.
 * @returns {void}
 */
export default function setTransactionName(name: string, domain: string = ''): void {
  // @ts-expect-error window property
  const { newrelic } = window;

  if (!newrelic) {
    return reportError('newrelic script is not yet loaded');
  }

  if (newrelic) {
    if (!name) {
      return reportError('Incorrect argument for setTransactionName! name was undefined');
    }

    // sets name for BrowserInteraction event
    const interaction = newrelic.interaction();

    if (interaction) {
      interaction.setName(name);
    }

    // sets name for PageView event
    newrelic.setPageViewName(name, domain);
  }
}
