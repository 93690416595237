import { isEmpty } from '../../shared/utils';
import { getFlattenedAttributes } from './get-flattened-attributes';

/**
 * logApmError function
 *
 * This function logs single key and value.
 * @param {Object} error - The error object for logging.
 * @param {Object} attributes - The customErrorMessage for logging.
 * @returns {void}
 */

export function getCustomErrorAttributes(error: Error, attributes?: Record<string, any>) {
  const { message, tags } = attributes || {};
  let customMessage = error?.stack ?? '';

  let customAttributes = {};

  if (typeof message === 'string') {
    customMessage = `${message}\n${customMessage}`;
  }

  if (typeof message === 'object' && !isEmpty(message)) {
    customAttributes = { ...customAttributes, ...getFlattenedAttributes({ error: { ...message } }) };
  }

  if (typeof tags === 'object' && !isEmpty(tags)) {
    customAttributes = { ...customAttributes, ...getFlattenedAttributes({ error: { tags } }) };
  }

  return { 'error.message': customMessage, ...customAttributes };
}
