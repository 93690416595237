import { reportError } from '../../error-handler';
import { isObject } from '../../shared/utils';
import { getFlattenedAttributes } from '../shared/get-flattened-attributes';

/**
 * logCustomEvent function
 *
 * This function is used to log custom event and corresponding attributes to mitigate with the current limitation of 64 attributes
 * part of the default transaction logging
 *
 * @param {String} eventType - The eventType to be recorded as table in newrelic
 * @param {Object} attributes - The corresponding custom attributes to be recorded
 * @returns {void}
 */
export default function logCustomEvent(eventType: string, attributes: Record<string, any>) {
  // @ts-ignore
  const { newrelic } = window;

  if (!newrelic) {
    return reportError('newrelic script is not yet loaded');
  }

  if (!isObject(attributes)) {
    return reportError(
      `Incorrect arguments for logCustomEvent! Second argument should be an object with one or multiple key/value pairs but found ${typeof attributes}`
    );
  }

  if (eventType && newrelic.recordCustomEvent) {
    newrelic.recordCustomEvent(eventType, getFlattenedAttributes(attributes));
  } else if (eventType && newrelic.addPageAction) {
    newrelic.addPageAction(eventType, getFlattenedAttributes(attributes));
  }
}
