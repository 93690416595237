import { isObject, isEmpty } from '../../shared/utils';
import { reportError } from '../../error-handler';
import logApmDatum from './log-apm-datum';

/**
 * logApmData function
 *
 * This function logs single key and value.
 * @param {Object} customAttributes - The key, value object for logging.
 * @returns {void}
 */
export default function logApmData(customAttributes: Record<string, any>) {
  if (!customAttributes || isEmpty(customAttributes)) {
    return reportError('No or empty custom attributes provided for logApmData');
  }

  if (!isObject(customAttributes)) {
    return reportError(
      `Incorrect arguments for logApmData! First argument should be an object with one or multiple key/value pairs but found ${typeof customAttributes}`
    );
  }

  Object.entries(customAttributes).forEach(([key, value]) => {
    logApmDatum(key, value);
  });
}
