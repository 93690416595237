/**
 * Bertie
 * @returns {void}
 */

import CircularBuffer from 'web/circular-buffer/index';

// IMPORTANT NOTE: validator.js uses 'tv4' which is a very heavy module, therefore we need to be extra carefull here and load it only when we
// are not in production!
const validator = process.env.NODE_ENV === 'production' ? null : require('./helpers/validator/');

const LISTENER_ERROR = 'Error in Bertie listener';

/**
 * Dispatcher system for the analytics to hook into.
 * When initializing the bus, two parameters are required:
 * - A global hook, if used in a browser, that would be the 'window' object such as external analytics libraries can hook
 * into bertie as window.bertie.
 * - A schema that will be validated against in development mode.
 * Note that in dev mode, the events will be validated against the JSON schema and warnings shown if they do not match it.
 * The current version of Bertie is an instance of the emitter2 ( see:<https://github.com/asyncly/EventEmitter2> ).
 */
class Bertie {
  /**
   * Constructor.
   * @param {object} globalHook - A global hook (i.e. 'window' object).
   * @param {object} schema - Json schema used for validation
   * @param {number} bufferLimit - Limit of the buffer
   * @param {number} maxListeners - Max number of listeners allowed
   * @param {object} logger - instance of a logger which default to the console one
   * @param {object} globalPayload - Contains properties to be sent with every event
   * @returns {void}
   */
  constructor(globalHook, schema, bufferLimit = 50, maxListeners = 50, logger = console, globalPayload = {}) {
    this.schema = schema;
    this.bufferLimit = bufferLimit;
    this._listeners = {};
    this._onAnyListeners = [];
    this.maxListeners = maxListeners;
    this.buffer = new CircularBuffer(bufferLimit);
    this.logger = logger;
    this.globalPayload = globalPayload;

    this.defaultGlobalPayload = {
      bertieVersion: schema && schema.version
    };

    /* istanbul ignore else  */
    if (typeof globalHook !== 'undefined') {
      globalHook.bertie = this;
    }
  }

  /**
   * It adds listeners
   * @param  {object} args -
   * @returns {void} -
   */
  addListener(...args) {
    this.on(...args);
  }

  /**
   * It checks that the number of current event listeners don't exceed the number passed to the constructor.
   * @returns {void}
   */
  checkMaxListenersNotExceeded() {
    const numNamedEventListeners = Object.keys(this._listeners).reduce(
      (total, key) => total + this._listeners[key].length,
      0
    );
    const numOnAnyListeners = this._onAnyListeners.length;
    const totalListeners = numNamedEventListeners + numOnAnyListeners;

    if (totalListeners > this.maxListeners) {
      this.logger.warn(
        `Maximum (${totalListeners}/${
          this.maxListeners
        }) Bertie event listeners exceeded: check for possible memory leak`
      );
    }
  }

  /**
   * It emits event.
   * @param {string} eventType - Type of the event
   * @param {object} payload - Payload object
   * @returns {void}
   */
  emit(eventType, payload = {}) {
    // Schema is only required for dev builds
    /* istanbul ignore else  */
    if (this.schema !== null && validator !== null) {
      // Init validator with schema.
      validator.addSchema(this.schema);

      // Only perform validation in dev mode.
      const { isValid, error } = validator.validate(eventType, payload);

      if (!isValid) {
        // Log error but do not dispatch.
        this.logger.error(error);
      }
    }

    const newPayload = {
      ...this.defaultGlobalPayload,
      ...payload,
      ...this.globalPayload,
      bertieTimeStamp: new Date().toISOString(),
      bertieType: eventType
    };

    this.buffer.push(newPayload);

    if (this._listeners[eventType]) {
      this._listeners[eventType].forEach((listener) => {
        try {
          listener(newPayload);
        } catch (e) {
          this.logger.error(LISTENER_ERROR, e);
        }
      });
    }

    this._onAnyListeners.forEach((listener) => {
      try {
        listener(newPayload);
      } catch (e) {
        this.logger.error(LISTENER_ERROR, e);
      }
    });
  }

  /**
   * It checks that the param passed is a function, otherwise it throws an error.
   * @param {function} listener - param passed to be checked.
   * @returns {boolean|Error} - It returns true if listener is a function or throw an error otherwise.
   */
  isListenerTypeFunction(listener) {
    if (typeof listener !== 'function') {
      throw new Error('Listener argument is not a function');
    }

    return true;
  }

  /**
   * It removes listeners. Alias for removeListener.
   * @param {string} type - type of event
   * @param {string} listenerToRemove -
   * @returns {void} -
   */
  off(type, listenerToRemove) {
    /* istanbul ignore else  */
    if (Array.isArray(this._listeners[type])) {
      this._listeners[type] = this._listeners[type].filter((listener) => listener !== listenerToRemove);
    }
  }

  /**
   * When the event is emitted, call the listeners
   * @param {string} type - type of event
   * @param  {object} listeners - listeners of the event type
   * @returns {void} -
   */
  on(type, ...listeners) {
    listeners.forEach((listener) => {
      /* istanbul ignore else  */
      if (this.isListenerTypeFunction(listener)) {
        this._listeners[type] = this._listeners[type] || [];
        this._listeners[type].push(listener);
        this.checkMaxListenersNotExceeded();

        this.buffer
          .toArray()
          .filter(({ bertieType }) => bertieType === type)
          .forEach((payload) => {
            try {
              listener(payload);
            } catch (e) {
              this.logger.error(LISTENER_ERROR, e);
            }
          });
      }
    });
  }

  /**
   * It call all the listeners.
   * @param  {object} listeners - listeners to call
   * @returns {void} -
   */
  onAny(...listeners) {
    listeners.forEach((listener) => {
      /* istanbul ignore else  */
      if (this.isListenerTypeFunction(listener)) {
        this._onAnyListeners = Array.isArray(this._onAnyListeners) ? this._onAnyListeners : [];
        this._onAnyListeners.push(listener);
        this.checkMaxListenersNotExceeded();

        this.buffer.toArray().forEach((payload) => {
          try {
            listener(payload);
          } catch (e) {
            this.logger.error(LISTENER_ERROR, e);
          }
        });
      }
    });
  }

  /**
   * It call the listener only once.
   * @param {string} type - type of event
   * @param {function} listener - listener callback
   * @returns {void} -
   */
  once(type, listener) {
    /* istanbul ignore else  */
    if (this.isListenerTypeFunction(listener)) {
      /**
       * It calls the listener and it removes it.
       * @param {string} event - type of event
       * @returns {void} -
       */
      const cb = (event) => {
        try {
          listener(event);
          this.off(type, cb);
        } catch (e) {
          this.logger.error(LISTENER_ERROR, e);
        }
      };

      const eventsOfType = this.buffer.toArray().find((evt) => evt.bertieType === type);

      if (eventsOfType) {
        try {
          listener(eventsOfType);
        } catch (e) {
          this.logger.error(LISTENER_ERROR, e);
        }
      } else {
        this.on(type, cb);
      }
    }
  }

  /**
   * It calls a listener only once after the set timeout.
   * @param {string} type - type of event
   * @param {function} listener - listener callback
   * @param {number} timeoutDuration - timeout duration
   * @returns {void} -
   */
  onceTimed(type, listener, timeoutDuration) {
    /* istanbul ignore else  */
    if (this.isListenerTypeFunction(listener)) {
      const timeoutId = setTimeout(() => {
        const fallbackEvent = {
          bertieTimeStamp: new Date().toISOString(),
          bertieType: type
        };
        this.off(type, wrappedListener); // eslint-disable-line no-use-before-define

        try {
          listener(fallbackEvent);
        } catch (e) {
          this.logger.error(LISTENER_ERROR, e);
        }
      }, timeoutDuration);

      /**
       * Callback called for clearing the timeout and removing the listener.
       * @param {string} event - type of event
       * @returns {void} -
       */
      const wrappedListener = (event) => {
        clearTimeout(timeoutId);
        this.purgeEventsOfType(type);
        this.off(type, wrappedListener);

        try {
          listener(event);
        } catch (e) {
          this.logger.error(LISTENER_ERROR, e);
        }
      };

      this.on(type, wrappedListener);
    }
  }

  /**
   * It purges all the listeners.
   * @return {void} -
   */
  purgeAll() {
    this.purgeBuffer();
    this.removeAllListeners();
  }

  /**
   * It purges the buffer.
   * @returns {void} -
   */
  purgeBuffer() {
    this.buffer = new CircularBuffer(this.bufferLimit);
  }

  /**
   * It purges events of a specifc type
   * @param {string} eventType -
   * @returns {void} -
   */
  purgeEventsOfType(eventType) {
    this.buffer.filterBuffer((event) => event.bertieType !== eventType);
  }

  /**
   * It removes all listeners.
   * @returns {void} -
   */
  removeAllListeners() {
    this._listeners = {};
    this._onAnyListeners = [];
  }

  /**
   * It removes a specific linester
   * @param  {object} args - listener to be removed
   * @returns {void} -
   */
  removeListener(...args) {
    this.off(...args);
  }
}

export default (...args) => {
  const bertie = new Bertie(...args);
  return bertie;
};
